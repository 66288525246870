import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { getNotification } from '../Service/Api';

const PopupModal = () => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const datas = await getNotification();
      setData(datas);
    };
    getData();
  }, []);

  useEffect(() => {
    const modalElement = document.getElementById("exampleModalToggle");
    if (modalElement) {
      const modal = new window.bootstrap.Modal(modalElement);
      modalElement.addEventListener('hidden.bs.modal', handleModalHidden);
      modal.show();
    }

    // Initialize carousel with JavaScript to ensure autoplay
    const carouselElement = document.getElementById("modalH");
 

    return () => {
      if (modalElement) {
        modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
      }
    };
  }, [data]);

  const handleModalHidden = () => {
    const modalBackdrop = document.getElementsByClassName("modal-backdrop")[0];
    if (modalBackdrop) {
      modalBackdrop.parentNode.removeChild(modalBackdrop);
    }
  };
  var settings = { 
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow:1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false, 
    adaptiveHeight: true
  };
  return (
    <>
      {data?.length > 0 && (
        <div className="popup modal fade" id="exampleModalToggle" aria-labelledby="exampleModalToggleLabel" tabIndex="-1" style={{ display: "none" }} aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div id="modalH" className=" " >
              <Slider {...settings}>
                  {data.map((item, index) => (
                    <div key={index} className="item" >
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      <div className="modal-body">
                        <div className="card modalcard">
                          <div className="row">
                            <div className="card-body">
                              <h1>{item.title}</h1>
                              <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.image[0]}`} className="img-fluid" alt="St. Mary’s Convent Sr. Sec. School, Tigaon" />
                              <p>{item.description}</p>
                              <div className="clearfix"></div>
                              <div className="modal-bottom">
                                {item.url.length > 0 && (<Link target="_blank" to={item?.url} className="btn btn-primary btn-sm urlTitle">{item.urlTitle}</Link>)}
                                {item.attachments &&(<Link target="_blank" to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} className="btn btn-primary btn-sm">Attachment</Link>)}
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
               </Slider>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PopupModal;
