import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { getNews } from '../Service/Api';
const HomeNews = () => {
    var settings = { 
        autoplay: true,
        infinite: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false, 
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 1300,
              settings: {
                slidesToShow: 3,
                slidesToScroll:1,
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                slidesToScroll:1,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow:1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };

      const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getNews();
        setNewsData(data);
      } catch (error) {
        console.error("Failed to fetch news:", error);
        setNewsData([]); // Set to empty to ensure the empty state is handled
      }
    };
    fetchData();
  }, []);

  const emptyArray = [
    { title: "News Title ", description: "Stay tuned for latest updates" },
    { title: "News Title ", description: "Stay tuned for latest updates" },
    { title: "News Title ", description: "Stay tuned for latest updates" },
    { title: "News Title ", description: "Stay tuned for latest updates" }
  ];


  return (
     <> 
     <div className="row">
     <Slider {...settings}>

      {newsData.length > 0 ? 
          newsData.map((item, index) => (
            <div className="item" key={index}>
     <div className="newssecbox">
     <div className="newssecboxitem">
          <div className="newssecboxdate"> <h3>{index + 1}</h3></div>
         <div className="newssecitem">
             <p className="newssecitemtitle">{item.title} </p>
             <p className="newssecitemdesc">{item.description}</p>
         </div>
        <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STMST/public/Images/attach.png" className="img-fluid"/></Link>
     </div>
</div>
      </div>

)) : 
emptyArray.map((data, index) => (
  <div className="item" key={index}>
  <div className="newssecbox">
  <div className="newssecboxitem">
       <div className="newssecboxdate"><h3>{index + 1}</h3></div>
      <div className="newssecitem">
          <p className="newssecitemtitle">{data.title} </p>
          <p className="newssecitemdesc">{data.description}</p>
      </div>
     <Link to="#" target="_blank"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/STMST/public/Images/attach.png" className="img-fluid"/></Link>
  </div>
</div>
   </div>
))
}

       </Slider>
     </div>
     </>
  )
}

export default HomeNews