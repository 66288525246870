import React from 'react'
import { Link } from 'react-router-dom'
const ActivityPlanner = () => {
  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
     <li><Link to="/"> Home</Link> </li>
     <li> Academic </li>
     <li> Activity Planner   </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
     <div className="row">
           <div className="col-lg-12">
              <h1>   Activity Planner  </h1>
         </div>
         <iframe src="https://d280nq1n4mqyso.cloudfront.net/api/image/STMST/public/pdf/ActivityPlanner.pdf" height="800" width="100%">  </iframe>
     </div>
  
</div>
</div>
    </>
  )
}

export default ActivityPlanner
