
import { Link } from 'react-router-dom'
import React, { useState } from "react";
import { getHeader } from '../config';
import { form1 } from '../Service/Api';
// import { form1 } from "../Service/Api";
// import { getHeader } from "../config"
function PreAdmission() {
    
    const [formData, setFormData] = useState({
        name: "",
        class:"",
        fatherName: "",
        motherName: "",
        phone: "",
        email: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const type = 'Admission'; 
        console.log('Submitting form with type:', type); // Add this line for debugging
        try {
            const { header } = getHeader();
            const response = await form1(formData, header, type); 
            console.log('Form submitted successfully', response);
            document.querySelector(".primary-alert").innerHTML = "<p>Your Form Submitted Successfully.</p>";
            setFormData({ name: '', email: '', phone: '', motherName: '' , class: '', fatherName:''});
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
    return (
        <>

            <div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>
                    <li> Admission Open</li>
                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1> Admission Open </h1>
                        </div>
                    </div>
                </div>

                <div class="container curr-blk contact-items">
                <form onSubmit={handleSubmit}>
                    <div class="row">

                        <div className="col-lg-12 col-md-12">
                        <div className='primary-alert'></div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">

                                <div class="form-group form-space">
                                    <input onChange={handleChange} value={formData.name} name="name"  type="text"  class="form-control f-control" placeholder="Student's Name" required="" />
                                </div>

                                <div class="form-group form-space">
                                    <input  onChange={handleChange} value={formData.class} name="class" type="text" id="txtname" class="form-control f-control" placeholder="Class Applying For" required="" />
                                </div>

                                <div class="form-group form-space">
                                    <input onChange={handleChange} value={formData.fatherName} name="fatherName" type="text" id="txtname" class="form-control f-control" placeholder="Father's Name" required="" />
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">

                            <div class="form-group form-space">
                                <input onChange={handleChange} value={formData.motherName} name="motherName" type="text" class="form-control f-control" placeholder="Mother's Name" required="" />
                            </div>

                            <div class="form-group form-space">
                                <input onChange={handleChange} value={formData.phone} name="phone"  type="text" maxlength="10" id="phone" class="form-control f-control" placeholder="Contact Number" required="" />
                            </div>

                            <div class="form-group form-space">
                                <input onChange={handleChange} value={formData.email} name="email" type="text" class="form-control f-control" placeholder="Enter your Email" required="" />
                            </div>

                        </div>
                        <div class="col-12">
                       <center> 
                       <input type="submit" class="btn btn-primary btn-lg"  value="Submit" />
                       </center>
                        </div>

                    </div>
                    </form>
                    <div class="clr30"></div>
                </div>
            </div>

        </>
    )
}

export default PreAdmission