import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import { getEvents } from '../Service/Api';
const HomeEvent = () => {
    var settings = { 
        autoplay: true,
        infinite: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow:3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false, 
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 1300,
              settings: {
                slidesToShow: 2,
                slidesToScroll:1,
              }
            },
            {
                breakpoint: 1070,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll:1,
                }
              },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
                slidesToScroll:1,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow:2,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
      };
      
const [data, setData] = useState([]);
useEffect(() => {
  const getData = async () => {
    const datas = await getEvents();
    setData(datas);
  };
  getData();
}, []);


const emptyArray = [
    { title: "Event Title", date: "DD/MM/YYYY", time: "00:00", venue: "School", images: "https://d280nq1n4mqyso.cloudfront.net/api/image/STMST/public/Images/events-pic.jpg", description: "Stay tuned for latest updates", location : "School" },
    { title: "Event Title", date: "DD/MM/YYYY", time: "00:00", venue: "School", images: "https://d280nq1n4mqyso.cloudfront.net/api/image/STMST/public/Images/events-pic.jpg", description: "Stay tuned for latest updates", location : "School" },
    { title: "Event Title", date: "DD/MM/YYYY", time: "00:00", venue: "School", images: "https://d280nq1n4mqyso.cloudfront.net/api/image/STMST/public/Images/events-pic.jpg", description: "Stay tuned for latest updates", location : "School" }
  ];
  return (
     <> 
     <div className="row">
     <Slider {...settings}>
     {data?.length > 0 ? data.map((item, index) => (
          <div className="item" key={index}>
       
                         <div className="homenewsbox">
                              <div className="homenewsboximg">
                                 <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.images[0]}`} className="img-fluid" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>
                                  <div className="homenewsboxtitle"><p> {item.title} </p></div>
                              </div>
                              <div className="homenewsboxdesc">
                                      <p>{item.description}</p>
                                 
                                 <div className="homenewsboxbottom">
                                    <span><i className="bi bi-geo-alt-fill"></i>{item.venue}</span>
                                     <span><i className="bi bi-clock-fill"></i> {item.time} </span>
                                 </div>
                              </div>
                          </div>
                    </div>
                  )) : emptyArray.map((item, index) => (
                    <div className="item" key={index}>
       
                    <div className="homenewsbox">
                         <div className="homenewsboximg">
                            <img src={item.images} className="img-fluid" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>
                             <div className="homenewsboxtitle"><p>  {item.title} </p></div>
                         </div>
                         <div className="homenewsboxdesc">
                         <p>{item.description}</p>                    
                            
                            <div className="homenewsboxbottom">
                            <span><i className="bi bi-geo-alt-fill"></i>{item.venue}</span>
                        <span><i className="bi bi-clock-fill"></i> {item.time} </span>
                            </div>
                         </div>
                     </div>
               </div>
        ))}
        </Slider>
     </div>
     </>
  )
}

export default HomeEvent