import axios from "axios"
import { API_URL, getHeader } from "../config"

export const getHighlight = async() => {
    const { data } = await axios.get(API_URL + `/api/highlight/STMST`);
    console.log(data)
    return data;
} 

export const getNews = async() => {
    console.log("first")
    const { data } = await axios.get(API_URL + `/api/news/STMST`);
    console.log("news:",data)
    return data;
} 

export const getEvents = async() => {
    const { data } = await axios.get(API_URL + `/api/events/STMST`);
    console.log(data)
    return data;
} 

export const getEventsinner = async() => {
    const { data } = await axios.get(API_URL + `/api/events/All/STMST`);
    console.log(data)
    return data;
}

export const getBirthdays = async() => {
    const { data } = await axios.get(API_URL + `/api/birthdays/STMST`);
    console.log(data)
    return data;
}

export const getGallery = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/STMST`);
    return data;
} 

export const getGalleryInner = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/All/STMST`);
    return data;
} 

export const getSubGallery = async(id) => {
    console.log(id)
    const { data } = await axios.get(API_URL + `/api/subGallery?id=${id}`);
    console.log(data)
    return data;
} 

export const getNotification = async() => {
    const { data } = await axios.get(API_URL + `/api/notification/STMST`,getHeader());
    return data;
} 

export const getTopper = async() => {
    const { data } = await axios.get(API_URL + `/api/topper/STMST`,getHeader());
    return data;
} 

export const getOnlineRegistration = async() => {
    const { data } = await axios.get(API_URL + `/api/onlineRegistration/STMST`,getHeader());
    return data;
} 

export const getSliders = async() => {
    const { data } = await axios.get(API_URL + `/api/slider/STMST`,getHeader());
    return data;
} 

export const getPrincipalMessage = async() => {
    const { data } = await axios.get(API_URL + `/api/PrincipalMessage/STMST`, getHeader());
    return data;
} 

// export const getMedia = async() => {
//     const { data } = await axios.get(API_URL + `/api/media/SHSJ`, getHeader());
//     return data;
// } 

export const getVideo = async() => {
    const { data } = await axios.get(API_URL + `/api/video/all/STMST`, getHeader());
    return data;
} 

export const postTransferCertificate = async(name,admission) => {
    console.log(name,admission)

    const { data } = await axios.post(API_URL + `/api/tc/STMST`,{name,admission},getHeader());
    return data;
}
export const form1 = async (formData, header, type) => {
    try {
        const { data } = await axios.post(`${API_URL}/api/form/STMST/${type}`, formData, header);
        console.log(data)
        return data;
    } catch (error) {
        console.error('Error in form submission:', error);
        if (error.response) {
            console.error('Error response data:', error.response.data); 
        }
        throw error;
    }
  };
 