import React, { useEffect, useState } from 'react';
import { Link , useNavigate} from 'react-router-dom';
import { getGalleryInner } from "../Service/Api";
import LazyLoad from 'react-lazyload';
const PhotoGallery = () => {
    const [data, setData] = useState([]);
    const navigate= useNavigate()
  
   
  const [filterData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [year, setYear] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          const galleryData = await getGalleryInner();
          setData(galleryData);
          const uniqueYears = Array.from(
            new Set(galleryData.map((item) => new Date(item.date).getFullYear()))
          );
          uniqueYears.sort((a, b) => b - a);
          setYear(uniqueYears);
          console.log(galleryData);
          setFilteredData(galleryData); // Initialize filteredData with all data
        };
        fetchData();
      }, []);
    
  useEffect(() => {
    // Filter data based on selected year
    if (selectedYear && selectedYear !== "All") {
      const filteredData = data.filter((item) => {
        const itemYear = new Date(item.date).getFullYear();
        return itemYear === parseInt(selectedYear);
      });
      setFilteredData(filteredData);
    } else {
      // If selected year is "All" or not selected, set filterData to all data
      setFilteredData(data);
    }
  }, [selectedYear, data]); // Added 'data' as a dependency
  ; // Added 'data' as a dependency
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const handleMonth = (month) => {
    return monthsOfYear[month - 1];
  };

    


    const emptyArray = [
        { title: "Gallery Title", day: "DD", month: "MM", attachments: "https://d280nq1n4mqyso.cloudfront.net/api/image/STMST/public/Images/gallery-pic.jpg" },
        { title: "Gallery Title", day: "DD", month: "MM", attachments: "https://d280nq1n4mqyso.cloudfront.net/api/image/STMST/public/Images/gallery-pic.jpg" },
        { title: "Gallery Title", day: "DD", month: "MM", attachments: "https://d280nq1n4mqyso.cloudfront.net/api/image/STMST/public/Images/gallery-pic.jpg" },
        { title: "Gallery Title", day: "DD", month: "MM", attachments: "https://d280nq1n4mqyso.cloudfront.net/api/image/STMST/public/Images/gallery-pic.jpg" },
        
        
    ];

    const handleModal = (id) => {
        
        navigate(`/SubGallery?id=${id}`); // Pass the ID as a query parameter in the URL
    }
    return (
        <>
            <div className="innerslide">
                <ul className="breadcrumb">
                <li><Link to="/home"> Home</Link> </li>
                    <li>  Gallery</li>
                    <li>  Photo Gallery </li>
                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>  Photo Gallery </h1>
                        </div>
                    </div>
                    <div className="row tabs-dynamic">
                <div className="col-md-6 col-xl-2">
                    <div className="count-val">
                        <p>Total Count: {filterData.length}</p>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3">
                    <div className="month-selection">
                    <select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  className="yearSelection"
                >
                  <option value={"All"}>All Year</option>
                  {year.map((yr) => (
                    <option value={yr}>{yr}</option>
                  ))}
                  {/* <option value={2022}>2022</option>
                  <option value={2021}>2021</option>
                  <option value={2020}>2020</option>
                  <option value={2019}>2019</option>
                  <option value={2018}>2018</option> */}
                  {/* Add more years if necessary */}
                </select>
                    </div>
                </div>
                {/* <div className="col-md-6 col-xl-3">
                    <div className="month-selection">
                        <select className="monthSelect">
                            <option value="0">All Month</option>
                        </select>
                    </div>
                </div> */}
                {/* <div className="col-md-6 col-xl-4">
                    <div className="searchBox">
                        <input type="search" id="myInput" name="name" placeholder="Search Here.."/>
                    </div>
                </div> */}
            </div> 
            <div className="row years year2024 showYear">
  {filterData.length > 0 ? (
    filterData.map((item, index) => (
      <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-sm-12 galleryCount">
        <div className="gallery-inner">
          <div className="gallery-blks">
            <div className="backDiv"></div>
            <LazyLoad>  <img src={item.attachments.length ? `https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}` : 'placeholder-image-url'} className="img-fluid" alt="St. Mary's School" /></LazyLoad>
          </div>
          <div className="gallery-content">
            <div className="gallery-date">
              <h6>{new Date(item.date).getDate()} | {handleMonth(new Date(item.date).getMonth())}</h6>
              <div className="gall-num">
                <h5><span>Number Of Photos :</span>{item.attachments.length}</h5>
              </div>
            </div>
            <div className="gallery-title">
              <h3>{item.title}</h3>
            </div>
            <a href='#' onClick={() => handleModal(item._id)} className="view-more">View More <i className="bi bi-arrow-right"></i></a>
          </div>
        </div>
      </div>
    ))
  ) : (
    emptyArray.map((data, index) => (
      <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-sm-12 galleryCount" data-month="1">
        <div className="gallery-inner">
          <div className="gallery-blks">
            <div className="backDiv"></div>
            <img src={data.attachments || 'placeholder-image-url'} className="img-fluid" alt="St. Mary's School" />
          </div>
          <div className="gallery-content">
            <div className="gallery-date">
              <h6>{data.day} <span>{data.month}</span></h6>
              <div className="gall-num">
                <h5><span>Number Of Photos :</span>00</h5>
              </div>
            </div>
            <div className="gallery-title">
              <h3>{data.title}</h3>
            </div>
            <Link to="#" className="view-more">View More <i className="bi bi-arrow-right"></i></Link>
          </div>
        </div>
      </div>
    ))
  )}
</div>

                  
                </div>
            </div>
        </>
    );
}

export default PhotoGallery;
